import PlayerCard from "components/PlayerCard";
import { useNavigate } from "react-router-dom";
import { appPath } from "routes/routes";
import money from "assets/images/money.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPrize } from "store/gameReducer";
import { FaRupeeSign } from "react-icons/fa";
import apiService from "api/apiService";
import apiRoutes from "api/apiRoutes";
import { useEffect, useState } from "react";
import logo from "assets/images/Logo.png";
import userLocalStorage from "hooks/userLocalStorage";
import { toast } from "react-toastify";

// const prizes = [100, 1000, 10000];

const SelectPrize = () => {
  const { gamePlayers } = useSelector((state: any) => state.game);
  const [prizes, setPrizes] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {getLocalStorage} = userLocalStorage();
  const user = JSON.parse(getLocalStorage("user_data"));

  function startGame(value: any) {
    if(Number(user?.wallet_balance) < Number(value?.amount)){
      toast.error("You don't have sufficiant balance.")
      return;
    }
    dispatch(setPrize(value?.amount));
    if (gamePlayers == "single") {
      navigate(`/game/play/${value?.id}`, {
        state: {
          game_id: value?.id,
          game_type: gamePlayers,
        },
      });
    } else {
      navigate(`/game/play/${value?.id}`, {
        state: {
          game_id: value?.id,
          game_type: gamePlayers,
        },
      });
    }
  }

  const getGameList = () => {
    apiService
      .get(apiRoutes.GAME.GAME_LIST)
      .then((response) => {
        setPrizes(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGameList();
  }, []);

  return (
    <div className="flex flex-col h-[calc(100vh-130px)] md:h-[calc(100vh-75px)] mx-4 pt-14">
      <div className="flex items-center justify-center my-6">
        <img
          src={logo}
          height={"auto"}
          width={"20%"}
          className="cursor-pointer w-[150px] md:w-[20%]"
          onClick={() => navigate(appPath.home)}
        />
      </div>
      <div
        className={
          "grid grid-cols-2 sm:grid-cols-3 lg:flex lg:flex-wrap justify-center items-center md:w-[80%] md:mx-auto overflow-auto gap-10 max-w-[100vw]"
        }
      >
        {prizes.map((prize, index) => (
          <div
            onClick={() => startGame(prize)}
            key={index}
            className="text-white text-center"
          >
            <PlayerCard
              image={money}
              amount={prize?.amount}
              className="h-[20vh] w-full md:min-w-[15vw] md:h-[25vh]"
              imageSize="h-[40px] md:h-[50px] w-[40px] md:w-auto"
            />
            {/* <div className="text-2xl font-semibold flex items-center justify-center gap-1 mt-2">
              <FaRupeeSign color="white" className="h-5 w-5" />
              <span>{prize?.amount}</span>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectPrize;
