import axios from "axios";
import appConfig from "config/appConfig";

const http = axios.create({
  baseURL:
    appConfig.environment == "development"
      ? appConfig.apiUrl.dev
      : appConfig.apiUrl.live,
  timeout: 10000,
});

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    console.log(response)
    if (response?.data?.code != 200) {
      return Promise.reject(response.data ? response.data : response);
    }

    return response;
  },
  (error) => {
    const { response } = error;
    if (response) {
      if (response?.data?.code === 401) {
        localStorage.clear();
        window.location.href = "/login";
      }
      console.log(error, response)
      return response;
    }
  }
);

export default http;
