"use client";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appPath } from "routes/routes";
import waiting from "../assets/gif/hourglass.gif";
import apiService from "api/apiService";
import apiRoutes from "api/apiRoutes";

interface IWaitingModalProps {
  setShowNoPlayersModal?: (value: boolean) => void;
  startCountDown: number;
  gameData: any;
  remainingTimeValue: any;
}

const WaitingModal = ({
  setShowNoPlayersModal,
  startCountDown,
  gameData,
  remainingTimeValue,
}: IWaitingModalProps) => {
  const [time, setTime] = useState<number>(60);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (time != 0) {
        setTime(time - 1);
      } else {
        setShowNoPlayersModal(true);
        setTimeout(() => {
          handleLeaveGame();
        }, 5000);
      }
    }, 1000);
  }, [time]);

  const handleLeaveGame = () => {
    const _form: any = new FormData();
    _form.append("card_game_id", gameData?.gameSession?.card_game_id);
    _form.append("card_game_session_id", gameData?.gameSession?.id);
    _form.append("left_time", `${60000 - remainingTimeValue * 1000}`);
    _form.append("player_id", gameData?.cardGamePlayer?.id);

    apiService
      .post(apiRoutes.GAME.GAME_LEFT, _form)
      .then((response) => {
        if (response?.data?.status == "Left") {
          navigate(appPath.home);
        }
        setShowNoPlayersModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="grid place-items-center gap-6">
      <div className="flex justify-center">
        <img src={waiting} alt="waiting" width={80} height={80} />
      </div>
      {startCountDown > 0 ? (
        <p>Game is starting in {startCountDown}...</p>
      ) : (
        <>
          <p className="font-semibold text-black text-lg md:text-2xl text-center px-4 md:px-10 py-4">
            Waiting for other players to join...
          </p>
          <p className="text-black text-xl text-center font-bold">
            Waiting time remaining {time}...
          </p>{" "}
        </>
      )}
    </div>
  );
};

export default WaitingModal;
