import React, { useEffect, useRef, useState } from "react";
import crown from "assets/images/crown.png";
import { FaPlus } from "react-icons/fa";
import { RiCloseLine, RiLoginBoxLine } from "react-icons/ri";
import { BsInfoCircle } from "react-icons/bs";
import TimerComponent from "components/TimerComponent";
import { cardTypeEnum, teamSize } from "types/enum";
import CardGroup from "components/CardGroup";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import apiService from "api/apiService";
import apiRoutes from "api/apiRoutes";
import { Router, useLocation, useNavigate } from "react-router-dom";
import MultiPlayerBox from "./MultiPlayerBox";
import { echo } from "utils/pusher/PusherClient";
import userLocalStorage from "hooks/userLocalStorage";
import clsx from "clsx";
import { appPath } from "routes/routes";
import CommonModal from "components/CommonModal";
import { Modal, ModalDialog, Typography } from "@mui/joy";
import LeaveWarnModal from "components/LeaveWarnModal";
import { useDispatch } from "react-redux";
import {
  setGameDataObj,
  setGameStartStatus,
  setWalletBalance,
} from "store/gameReducer";
import waiting from "../../assets/gif/hourglass.gif";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import profile from "../../assets/images/profile.png";
import WaitingModal from "components/WaitingModal";

interface ICard {
  id: number;
  value: number;
  image: any;
}

const cardTypes = [
  cardTypeEnum.heart,
  cardTypeEnum.spade,
  cardTypeEnum.diamond,
  cardTypeEnum.club,
];

const modalStyles = {
  p: 0,
  background: "none",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const GamePlay = () => {
  const [droppedCards, setDroppedCards] = useState([]);
  const [winningNumber, setWinningNumber] = useState(25);
  const [remainingNumber, setRemainingNumber] = useState(25);
  const [droppedCardTypes, setDroppedCardTypes] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [gameData, setGameData] = useState(null);
  const [gameType, setGameType] = useState("single");
  const location = useLocation();
  const { getLocalStorage, setLocalStorage } = userLocalStorage();
  const user = JSON.parse(getLocalStorage("user_data"));
  const navigate = useNavigate();
  const [gameTime, setGameTime] = useState(null);
  const [dropArea, setDropArea] = useState(0);
  const [activeDeck, setActiveDeck] = useState(null);
  const [remainingTime, setRemainingTime] = useState<any>(60);
  const [openWarnModal, setOpenWarnModal] = useState(false);
  const [isGameStartModal, setIsGameStartModal] = useState(true);
  const [startCountDown, setStartCountDown] = useState(0);
  const playersDataRef = useRef<any>([]);
  const dispatch = useDispatch();
  const [leftUserData, setLeftUserData] = useState(null);
  const [rulesModal, setRulesModal] = useState(false);
  const { remainingTimeValue, playersCount } = useSelector(
    (state: any) => state?.game
  );
  const [lastUserModal, setLastUserModal] = useState(false);

  const handleActiveDeck = (index) => {
    setActiveDeck(index);
  };

  const handleOnDragEnd = (result: any) => {
    if (Object.keys(droppedCards)?.includes(result?.destination?.droppableId)) {
      window.navigator.vibrate([200]);
      toast.error("You can't drop card here.");
      return;
    }
    if (!result.destination) {
      return;
    }

    if (
      Number(result?.destination?.droppableId?.split("-")[1]) > Number(dropArea)
    ) {
      window.navigator.vibrate([200]);
      toast.error("please drop in the previous slots first.");
      return;
    }

    let cardArr = [];

    Object.entries(droppedCards).forEach(([key, value]) => {
      cardArr.push(value?.index);
    });

    if (Object.keys(droppedCards)?.length > 0) {
      if (
        cardArr.every(
          (el) => Number(result?.draggableId?.split("/")[1]) >= Number(el)
        )
      ) {
        const { source, destination, draggableId } = result;
        if (destination.droppableId.startsWith("dropbox-")) {
          // source id = card-${cardType}/${currentCard}}
          const draggedCard = cardTypes[source.index];
          setDroppedCards((prev) => ({
            ...prev,
            [destination.droppableId]: {
              ["index"]: draggableId.split("/")?.[1],
              type: draggedCard,
              source: source?.index,
            },
          }));
          setDroppedCardTypes([...droppedCardTypes, draggedCard]);
          if (draggableId.split("/")?.[1] == 10) {
            setRemainingNumber(remainingNumber - 0);
          } else {
            setRemainingNumber(remainingNumber - draggableId.split("/")?.[1]);
          }
        }
      } else {
        window.navigator.vibrate([200]);
        toast.error(
          "card must be greater than or equal to the previous numbers"
        );
      }
    } else {
      const { source, destination, draggableId } = result;
      if (destination.droppableId.startsWith("dropbox-")) {
        // source id = card-${cardType}/${currentCard}}
        const draggedCard = cardTypes[source.index];
        setDroppedCards((prev) => ({
          ...prev,
          [destination.droppableId]: {
            ["index"]: draggableId.split("/")?.[1],
            type: draggedCard,
            source: source?.index,
          },
        }));
        setDroppedCardTypes([...droppedCardTypes, draggedCard]);
        if (draggableId.split("/")?.[1] == 10) {
          setRemainingNumber(remainingNumber - 0);
        } else {
          setRemainingNumber(remainingNumber - draggableId.split("/")?.[1]);
        }
      }
    }
    setDropArea(dropArea + 1);
  };

  const getCardList = () => {
    apiService
      .get(apiRoutes.GAME.CARD_LIST)
      .then((response) => {
        setCardData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(()=>{
  //   window.onbeforeunload = function() {
  //     return "Are you sure you want to navigate away?";
  //   }
  // }, [])

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const setIntervalTime = () => {
      intervalId = setInterval(() => {
        setStartCountDown(prev => Math.max(prev - 1, 0));
      }, 1000); 
    }

    echo
      .channel(`card-game-join${user?.id}`)
      .listen("CardGameJoinEvent", (e: any) => {
        console.log(e, "event received join");
        playersDataRef.current = e?.message;
      });
    echo
      .channel(`card-game-start${user?.id}`)
      .listen("CardGameStartEvent", (event: any) => {
        console.log("event game start", event);
         setStartCountDown(5); // Reset countdown
        if (intervalId) clearInterval(intervalId); // Clear any existing intervals
        setIntervalTime(); // Start new interval

        // Set a timeout for game start
        setTimeout(() => {
          setStartCountDown(0);
          setGameTime(event?.message?.time);
          if (intervalId) clearInterval(intervalId); // Clear interval after timeout
        }, 5000);
      });
    echo
      .channel(`card-game-play${user?.id}`)
      .listen("CardGamePlayEvent", (event: any) => {
        console.log("game play event", event);
      });
    echo
      .channel(`card-game-win${user?.id}`)
      .listen("CardGameWinEvent", (e: any) => {
        console.log(e, "event received win");
        if (e?.message?.status == "Win" && e?.message?.user?.id == user?.id) {
          navigate(appPath.gameResult, {
            state: {
              status: true,
            },
          });
        } else {
          navigate(appPath.gameResult, {
            state: {
              status: false,
            },
          });
        }
        dispatch(setGameStartStatus(false));
      });
    echo
      .channel(`card-game-lose${user?.id}`)
      .listen("CardGameLoseEvent", (e: any) => {
        if (e?.message?.status == "Lose" && e?.message?.user?.id == user?.id) {
          navigate(appPath.gameResult, {
            state: {
              status: false,
            },
          });
        }
        dispatch(setGameStartStatus(false));
        // else {
        //   navigate(appPath.gameResult, {
        //     state: {
        //       status: true,
        //     },
        //   });
        // }
        console.log(e, "event received lose");
      });

    echo
      .channel(`card-game-left${user?.id}`)
      .listen("CardGameLeftEvent", (event: any) => {
        console.log(event, "game left event");
        dispatch(setGameStartStatus(false));
        const newArr = playersDataRef?.current?.filter(
          (prev) => prev?.user_id != event?.message?.user_id
        );
        playersDataRef.current = newArr;
        if (newArr.length == 1) {
          // if (gameData) {
          setLastUserModal(true);
          setTimeout(() => {
            handleLeaveGame();
          }, 2000);
          // }
        }
        if (event?.message?.user_id != user?.id) {
          setLeftUserData(event?.message);
          setTimeout(() => {
            setLeftUserData(null);
          }, 2000);
        }
      });

    if (location?.state?.game_id) {
      setGameType(location?.state?.game_type);
      handleCreateGame(location?.state?.game_id, location?.state?.game_type);
    }
    getCardList();
  }, []);

  const handleLeaveGame = () => {
    const _form: any = new FormData();
    console.log(gameData)
    _form.append("card_game_id", gameData?.gameSession?.card_game_id);
    _form.append("card_game_session_id", gameData?.gameSession?.id);
    _form.append("left_time", `${60000 - remainingTimeValue * 1000}`);
    _form.append("player_id", gameData?.cardGamePlayer?.id);

    apiService
      .post(apiRoutes.GAME.GAME_LEFT, _form)
      .then((response) => {
        if (response?.data?.status == "Left") {
          navigate(appPath.home);
        }
        setLastUserModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const handlePopState = () => {
      setOpenWarnModal(true);
      dispatch(setGameStartStatus(false));
    };

    window.addEventListener("popstate", handlePopState);

    // Add a new history entry
    window.history.pushState({}, "", `/game/play/${location?.state?.game_id}`);
    navigate(`/game/play/${location?.state?.game_id}`);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleCreateGame = (gameId, game_Type) => {
    const formData = new FormData();
    formData.append("players", game_Type == "single" ? "1" : playersCount);
    formData.append("card_game_id", gameId);
    apiService
      .post(apiRoutes.GAME.JOIN_GAME, formData)
      .then((response) => {
        console.log(response)
        if(response?.code == 200){
          setGameData(response?.data);
          setWinningNumber(response?.data?.gameSession?.winning_number);
          setRemainingNumber(response?.data?.gameSession?.winning_number);
          dispatch(setGameDataObj(response?.data));
          dispatch(setGameStartStatus(true));
          updateWalletBalance();
        } else {
          throw response
        }
      })
      .catch((error) => {
        toast.error(error?.message || "Something went wrong");
        navigate(appPath.home);
        console.log("error", error);
      });
  };

  const updateWalletBalance = () => {
    apiService
      .get(apiRoutes.USER.WALLET_BALANCE)
      .then((response) => {
        if (response) {
          let user = JSON.parse(getLocalStorage("user_data"));
          user.wallet_balance = response?.data;
          setLocalStorage("user_data", JSON.stringify(user));
          dispatch(setWalletBalance(response?.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePlayGame = (bidNumber) => {
    const _form = new FormData();
    _form.append("card_game_session_id", gameData?.gameSession?.id);
    _form.append("card_game_player_id", gameData?.cardGamePlayer?.id);
    _form.append("bid_number", bidNumber == 10 ? 0 : bidNumber);
    apiService
      .post(apiRoutes.GAME.PLAY_GAME, _form)
      .then((response) => {
        // if(Object.keys(droppedCards)?.length == 3){
        //   handleWinEvent();
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (Object.keys(droppedCards)?.length > 0) {
      handlePlayGame(
        droppedCards?.[`dropbox-${Object.keys(droppedCards)?.length - 1}`]
          ?.index
      );
    }
  }, [droppedCards]);

  return (
    <>
      <LeaveWarnModal
        gameData={gameData}
        openWarnModal={openWarnModal}
        setOpenWarnModal={setOpenWarnModal}
      />
      <CommonModal
        isModalOpen={rulesModal}
        setIsModalOpen={setRulesModal}
        content={
          <div>
            <div className="flex justify-end">
              <RiCloseLine
                className="text-2xl cursor-pointer"
                onClick={() => setRulesModal(false)}
              />
            </div>
            <p className="text-center font-bold text-lg md:text-xl">Game Rules:</p>
            <ul className="text-xs md:text-base list-disc ml-4 mt-2">
              <li>
                During the first 30 seconds, players may only observe the game
                and are not allowed to play.
              </li>
              <li>
                Players can place a card that matches or exceeds the number on
                the previous card.
              </li>
              <li>A "0" card is the highest value, while "1" is the lowest.</li>
              <li>
                Cards must be placed in sequential order, filling the first
                position before moving to the next.
              </li>
            </ul>
            <p className="text-center font-bold text-lg md:text-xl mt-6">खेल के नियम:</p>
            <ul className="text-xs md:text-base list-disc ml-4 mt-2">
              <li>
                पहले 30 सेकंड के दौरान, खिलाड़ी केवल खेल को देख सकते हैं और
                खेलने की अनुमति नहीं है।
              </li>
              <li>
                खिलाड़ी पिछले कार्ड पर अंक से मेल खाने या उससे अधिक अंक वाले
                कार्ड रख सकते हैं।
              </li>
              <li>"0" कार्ड उच्चतम मान है, जबकि "1" सबसे कम है।</li>
              <li>
                कार्ड को क्रमिक क्रम में रखना होगा, पहले स्थान को भरना होगा, फिर
                अगले पर जाना होगा।
              </li>
            </ul>
          </div>
        }
      />
      {gameType == teamSize.multi && !gameTime && (
        <CommonModal
          isModalOpen={isGameStartModal}
          content={
            <WaitingModal 
              startCountDown={startCountDown}
              setShowNoPlayersModal={setLastUserModal}
              gameData={gameData}
              remainingTimeValue={remainingTimeValue}
            />
            // <div>
            //   <div className="flex justify-center">
            //     <img src={waiting} alt="waiting" width={80} height={80} />
            //   </div>
            //   <p className="font-semibold text-black text-lg md:text-2xl text-center px-4 md:px-10 py-4">
            //     {startCountDown > 0 ?  `Game is starting in ${startCountDown}...` : "Waiting for other players to join..."}
            //   </p>
            // </div>
          }
        />
      )}
      <CommonModal
        isModalOpen={lastUserModal}
        content={
          <div>
            <p className="font-semibold text-black text-center text-lg md:text-2xl px-4 md:px-10 py-4">
              There are no other player to play with.
              <br />
              So, we are redirecting you to home page.
            </p>
          </div>
        }
      />
      {/* {gameType == teamSize.multi && gameTime && remainingTime > 30 && (
        <Modal open={isGameStartModal}>
          <ModalDialog sx={modalStyles}>
            <Typography>
              <p className="font-semibold text-white text-3xl">
                Game is starting in {remainingTime - 30}...
              </p>
            </Typography>
          </ModalDialog>
        </Modal>
      )}
      {gameType == teamSize.single && remainingTime > 30 && (
        <Modal open={isGameStartModal}>
          <ModalDialog sx={modalStyles}>
            <Typography>
              <p className="font-semibold text-white text-3xl">
                Game is starting in {remainingTime - 30}...
              </p>
            </Typography>
          </ModalDialog>
        </Modal>
      )} */}
      <div
        className={clsx(
          "h-full w-full pt-16 md:pt-32 flex flex-col md:flex-row justify-between items-start gap-4 md:gap-10 lg:px-20 py-10 text-white overflow-hidden"
        )}
      >
        <div
          className={clsx(
            "flex flex-row-reverse md:flex-col-reverse h-full items-center w-full md:w-auto px-6 md:px-0",
            gameType == "multi" ? "justify-between" : "justify-between"
          )}
        >
          {/* timer */}
          {/* {gameType == "multi" ? ( */}
          <TimerComponent
            remainingNumber={remainingNumber}
            gameTime={gameTime}
            gameType={gameType}
            gameData={gameData}
            remainingTime={remainingTime}
            setRemainingTime={setRemainingTime}
          />
          {/* ) : (
            <div className="md:hidden block">
              <TimerComponent
                remainingNumber={remainingNumber}
                gameTime={gameTime}
                gameType={gameType}
                gameData={gameData}
                remainingTime={remainingTime}
                setRemainingTime={setRemainingTime}
              />
            </div>
          )} */}
          <div className="flex flex-col justify-center items-center">
            <img
              src={crown}
              height={80}
              width={80}
              alt="Crown"
              className="w-[40px] h-[40px] md:w-[70px] md:h-[70px]"
            />
            <div className="bg-white p-4 md:p-10 min-w-[60px] flex items-center justify-center rounded-2xl text-2xl md:text-4xl font-bold text-red-600">
              {winningNumber}
            </div>
            <span className="font-semibold md:text-2xl my-4 tracking-wider">
              Winning Number
            </span>
          </div>
        </div>

        <div
          className={clsx(
            "flex flex-col justify-center gap-10 w-[288px] mx-auto md:w-[43%]",
            gameType == teamSize.multi && remainingTime > 30 ? "pointer-events-none" : ""
          )}
        >
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className="flex items-center gap-4 md:gap-8 justify-center">
              {/* Drop boxes for cards */}
              {[0, 1, 2].map((_, index) => (
                <React.Fragment key={index}>
                  <Droppable droppableId={`dropbox-${index}`}>
                    {(provided: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={clsx(
                          "h-[100px] min-w-[70px] w-[70px] md:h-[150px] md:min-w-[110px] md:w-[110px] relative border-[3px] border-white rounded-sm p-[2px]"
                        )}
                      >
                        {droppedCards[`dropbox-${index}`] && (
                          <>
                            <img
                              src={`/cards/${
                                droppedCards[`dropbox-${index}`].type
                              }/${droppedCards[`dropbox-${index}`].index}.png`}
                              height={1000}
                              width={1000}
                              alt="card"
                              className="cursor-pointer h-full w-full object-cover rounded-sm overflow-hidden"
                            />
                            {/* <p className="text-black font-bold absolute right-[45%] -translate-y-[110px] text-2xl">
                            {droppedCards[`dropbox-${index}`].index}
                          </p> */}
                          </>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  {index < 2 && <FaPlus className="md:text-4xl" />}
                </React.Fragment>
              ))}
            </div>
            <Droppable
              droppableId="cards"
              direction="horizontal"
              isDropDisabled={true}
            >
              {(provided: any) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="flex gap-4 md:gap-8 justify-center w-max mx-auto"
                >
                  {cardTypes.map((type, index) => (
                    <CardGroup
                      cardType={type}
                      key={index}
                      index={index}
                      droppedCardTypes={droppedCardTypes}
                      cardData={cardData}
                      handleActiveDeck={handleActiveDeck}
                      activeDeck={activeDeck}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {/* <span className="font-semibold text-xl capitalize w-full text-center">
            Winning number remains {remainingNumber}
          </span> */}
          <div className="md:hidden">
            <MultiPlayerBox
              playersData={playersDataRef?.current}
              userData={user}
            />
          </div>
          <div className="bg-red-bg justify-center flex items-center mx-auto p-4 gap-4 rounded-lg w-max">
            <div>
              <img
                src={user?.profile_photo ? user?.profile_photo_url : profile}
                width={50}
                height={50}
                className="rounded-full"
              />
            </div>
            <div>
              <p className="text-sm font-semibold">User Id: {user?.id}</p>
              {/* <p className="text-sm">Name: {user?.name}</p> */}
            </div>
          </div>
          {leftUserData && (
            <span className="font-semibold text-xl capitalize w-full text-center">
              player {leftUserData?.id} left the game
            </span>
          )}
        </div>

        <div className="h-full flex flex-col items-stretch justify-between ">
          <div className="hidden md:block">
            <MultiPlayerBox
              playersData={playersDataRef?.current}
              userData={user}
            />
          </div>
          {/* {gameType == "multi" ? (
          // ) : (
          //   <div className="hidden md:block">
          //     <TimerComponent
          //       remainingNumber={remainingNumber}
          //       gameTime={gameTime}
          //       gameType={gameType}
          //       gameData={gameData}
          //       remainingTime={remainingTime}
          //       setRemainingTime={setRemainingTime}
          //     />
          //   </div>
          // )} */}
          <div className="flex gap-4 text-4xl">
            <BsInfoCircle
              className="cursor-pointer fixed md:static top-[65px] right-4 h-6 w-6 md:h-8 md:w-8"
              onClick={() => setRulesModal(true)}
            />
            {/* {remainingTime > 30 && ( */}
            <RiLoginBoxLine
              onClick={() => {
                if (remainingTime != 0) {
                  setOpenWarnModal(true);
                  // if (confirm("If you leave you lost the game.") == true) {
                  //   navigate(appPath.home);
                  // }
                } else {
                  navigate(appPath.home);
                }
              }}
              className="cursor-pointer fixed md:static bottom-[60px] right-4 h-8w-8"
            />
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default GamePlay;
