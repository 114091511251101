import clsx from "clsx";
import userLocalStorage from "hooks/userLocalStorage";
import React from "react";
import { FaRegUser, FaRupeeSign } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
import { teamSize } from "types/enum";

interface Props {
  type?: "single" | "multi" | any;
  image: any;
  amount?: number | string | any;
  className?: string;
  imageSize?: string;
}

const PlayerCard = ({ type, image, amount, className, imageSize }: Props) => {
  const {getLocalStorage} = userLocalStorage();
  const user = JSON.parse(getLocalStorage("user_data"));

  return (
    <div
      className={clsx(className ? className : "h-[25vh] w-full md:min-w-[20vw] md:h-[40vh]", "box-border border-0 rounded-2xl flex flex-col justify-center items-center gap-8 shadow-sm shadow-red-500 hover:cursor-pointer")}
      style={{
        background:
          "radial-gradient(50% 50% at 50% 50%, #DA1E1E 0%, #DC2525 54%, #AD1717 100%)",
        filter: Number(user?.wallet_balance) < Number(amount) ? "brightness(0.5)": "",
        cursor: Number(user?.wallet_balance) < Number(amount) ? "not-allowed": "pointer",
      }}
      onClick={()=>{
        window.navigator.vibrate([100])
      }}
    >
      {!amount && <div className="flex items-center gap-2">
        {type === teamSize.single ? (
          <FaRegUser color="white" />
        ) : (
          <FaUserFriends color="white" />
        )}
        <span className="capitalize text-white md:text-xl">{type}-Player</span>
      </div>}
      <img
        src={image}
        className={clsx("object-contain", imageSize ? imageSize : "h-[60px] md:h-[80px] w-[80px] md:w-auto")}
      />
      {amount && <div className="text-2xl font-semibold flex items-center justify-center gap-1 mt-2">
        <FaRupeeSign color="white" className="h-5 w-5" />
        <span>{amount}</span>
      </div>}
    </div>
  );
};

export default PlayerCard;
